import { RemixBrowser } from "@remix-run/react";
import { StrictMode, startTransition } from "react";
import { hydrateRoot } from "react-dom/client";

const enabled = ENV.MODE === "production" || ENV.MODE === "staging";

if (enabled && ENV.SENTRY_DSN) {
  import("./utils/monitoring.client").then(({ init }) => init());
}

async function hydrate() {
  startTransition(() => {
    hydrateRoot(
      document,

      <StrictMode>
        <RemixBrowser />
      </StrictMode>,
    );
  });
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}
